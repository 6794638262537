import Box from '../Box.jsx';
import PreloaderOverlay from '@/components/overlays/PreloaderOverlay.jsx';
import React from 'react';

const AuthenticationLayout = ({ children, ...properties }) => (
    <>
        <PreloaderOverlay />

        <Box minHeight={'100vh'} backgroundColor={'background.base'} p={40} {...properties}>
            {children}
        </Box>
    </>
);

export default AuthenticationLayout;
